import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import { lazy as loadable } from '@loadable/component'
import { useI18n } from '@rushplay/i18n'

import * as icons from './icons'
import { CustomFieldError } from './custom-field-error'
import { FieldLabel } from './field-label'
import { Input } from './input'
import { PasswordHints } from './password-hints'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))
export function InputPasswordField(props) {
  const [type, setType] = React.useState('password')
  const i18n = useI18n()
  const form = forms.useFormContext()
  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
    parse: getFieldParser(props.format, props.parse),
  })

  function handleChange(e) {
    if (props.customErrorKey) {
      props.onClearCustomErrorKey()
    }
    field.onChange(e)
  }

  return (
    <common.Flex flexDirection="column">
      {i18n.hasTranslation(field.label) && (
        <FieldLabel htmlFor={field.name}>
          {i18n.translate(field.label)}
        </FieldLabel>
      )}
      <Input
        appendIcon={
          type === 'password' ? icons.Visibility : icons.VisibilityOff
        }
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        id={`${form.name}-${field.name}`}
        name={field.name}
        placeholder={
          i18n.hasTranslation(field.placeholder)
            ? i18n.translate(field.placeholder)
            : ''
        }
        prependIcon={props.prependIcon}
        suppressVisualFeedback={props.suppressVisualFeedback}
        type={type}
        value={field.value}
        valid={R.isEmpty(field.errors)}
        visited={
          !R.includes(field.status, [
            forms.FieldStatus.PRISTINE,
            forms.FieldStatus.ABSENT,
          ])
        }
        onBlur={field.onBlur}
        onChange={handleChange}
        onClick={() => setType(type === 'password' ? 'text' : 'password')}
      />
      {props.suppressVisualFeedback ? null : props.customErrorKey ? (
        <CustomFieldError translationKey={props.customErrorKey} />
      ) : (
        <FieldResponse scope={props.scope} />
      )}
      {props.children}
      {props.autoComplete === 'new-password' && (
        <PasswordHints scope={props.scope} />
      )}
    </common.Flex>
  )
}

InputPasswordField.defaultProps = {
  normalize: value => value || undefined,
}

InputPasswordField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  customErrorKey: PropTypes.string,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parse: PropTypes.func,
  children: PropTypes.node,
  prependIcon: PropTypes.element,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
  onClearCustomErrorKey: PropTypes.func,
}
