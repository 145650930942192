import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as Notifications from '@rushplay/notifications'

import * as CombinedSelectors from '../combined-selectors'
import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Player from '../player'
import * as Session from '../session'
import { ProcessesIds, RegistrationStep } from '../constants'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form'
import { useMenuQueries } from '../use-menu-queries'
import { useServerConfiguration } from '../server-configuration'

import { dataSchema } from './data-schema'

const formName = 'register'

export function RegistrationDrawer() {
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const i18n = I18n.useI18n()
  const history = ReactRouter.useHistory()
  const { authenticated } = Herz.Auth.useSession()
  const [emailError, setEmailError] = React.useState(null)
  const [phoneError, setPhoneError] = React.useState(null)
  const [usernameError, setUsernameError] = React.useState(null)
  const [showVisualFeedback, setShowVisualFeedback] = React.useState(false)
  const { loginQuery } = useMenuQueries()
  const registrationInProgress = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [ProcessesIds.REGISTRATION],
    })
  )

  const fingerprint = Herz.Seon.useFingerprint()
  const dispatch = ReactRedux.useDispatch()
  const affiliateClickId = ReactRedux.useSelector(state =>
    CombinedSelectors.getAffiliateClickId(state)
  )

  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const currency = ReactRedux.useSelector(state =>
    Player.getCurrency(state.player)
  )
  const analytics = ReactRedux.useSelector(state => state.analytics)
  const affiliateSubId = Analytics.getSubId(analytics)
  const btag = Analytics.getBtag(analytics)
  const utmCampaign = Analytics.getUtmCampaign(analytics)
  const utmMedium = Analytics.getUtmMedium(analytics)
  const utmSource = Analytics.getUtmSource(analytics)

  const { countries, locale } = useServerConfiguration()

  const countryCallingCodes = countries.map(country =>
    country.countryCode.toString()
  )

  const countryNames = countries.map(country => country.name)

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: countryCallingCodes,
        maxYear: new Date(Date.now()).getFullYear() - 18,
      }),
    [countryCallingCodes]
  )

  function startRegistration() {
    dispatch([
      Processes.start(ProcessesIds.REGISTRATION),
      Analytics.registrationStarted(),
      Notifications.dismissAll(),
    ])
  }

  function handleCredentialsSubmit(data) {
    return dispatch([
      Processes.start(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
      Api.validateEmail(data.email, {
        success: res => {
          if (res.value?.errorCode) {
            setShowVisualFeedback(true)
            if (res.value?.message === 'email already exists') {
              setEmailError('errors.registration.email-uniqueness')
            } else {
              dispatch(
                Notifications.add({
                  message: 'errors.registration.email-verification.failed',
                  level: 'error',
                })
              )
            }
            return Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST)
          } else {
            return [
              Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
              Processes.start(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
              Api.validateUsername(data.username, {
                success: res => {
                  if (res.value?.errorCode) {
                    setShowVisualFeedback(true)
                    if (res.value?.message === 'username already exists') {
                      setUsernameError(
                        'errors.registration.username-uniqueness'
                      )
                    }
                    if (res.value?.message === 'username is inappropriate') {
                      setUsernameError(
                        'errors.registration.username-is-inappropriate'
                      )
                    } else {
                      dispatch(
                        Notifications.add({
                          message:
                            'errors.registration.username-verification.failed',
                          level: 'error',
                        })
                      )
                    }
                    return Processes.stop(
                      ProcessesIds.USERNAME_UNIQUENESS_REQUEST
                    )
                  } else {
                    setStep(RegistrationStep.Identity)
                    return [
                      Processes.stop(ProcessesIds.REGISTER_REQUEST),
                      Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                    ]
                  }
                },
                failure: () => {
                  setShowVisualFeedback(true)
                  return [
                    Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
                    Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                    Processes.stop(ProcessesIds.REGISTER_REQUEST),
                  ]
                },
                version: 1,
              }),
            ]
          }
        },
        failure: () => {
          setShowVisualFeedback(true)
          return [
            Processes.stop(ProcessesIds.REGISTER_REQUEST),
            Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
          ]
        },
        version: 1,
      }),
    ])
  }

  function prepareData(data) {
    return {
      affiliateClickId,
      affiliateSubId,
      allowEmail: data.promotional,
      allowSms: data.promotional,
      birthdate: `${data.bdayDay}/${data.bdayMonth}/${data.bdayYear}`,
      clientType,
      countryCallingCode: `+${data.countryCallingCode}`,
      countryCode:
        countries?.length === 1
          ? countries[0]?.alpha2
          : countries?.find(country => country.name === data.country)['alpha2'],
      currency,
      email: data.email,
      firstName: data.firstName.trim(),
      generateUsername: false,
      // Hardcodes gender because it's required on BE, but we don't care
      gender: 'Male',
      language: locale.language,
      lastName: data.lastName.trim(),
      mobile: data.phonenumber,
      netrefererBTag: btag,
      password: data.password,
      passwordConfirmation: data.password,
      phoneVerificationCode: data.phoneVerificationCode,
      username: data.username,
      utmCampaign,
      utmMedium,
      utmSource,
      seonSession: fingerprint.value,
      terms: data.terms,
      privacy: data.terms,
    }
  }

  function endRegistration() {
    dispatch([
      Processes.stop(ProcessesIds.REGISTRATION),
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
  }

  function handleRegister(data) {
    const userData = prepareData(data)

    dispatch([
      Processes.start(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
      Api.validatePhoneNumber(`+${data.countryCallingCode}`, data.phonenumber, {
        success: () => {
          setShowVisualFeedback(false)
          return [
            Processes.stop(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
            Processes.start(ProcessesIds.REGISTER_REQUEST),
            Processes.start(ProcessesIds.LOGIN_REQUEST),
            Api.registerPlayer(userData, {
              success: () => {
                endRegistration()
                history.push('/casino')
                return Session.login(
                  userData.email,
                  userData.password,
                  clientType,
                  fingerprint.value
                )
              },
              failure: res => {
                dispatch(Processes.stop(ProcessesIds.REGISTER_REQUEST))
                if (res.value.errors) {
                  Object.keys(res.value?.errors).forEach(item => {
                    dispatch(
                      Notifications.add({
                        message: `errors.registration.${item}`,
                        level: 'error',
                      })
                    )
                  })
                } else {
                  dispatch(
                    Notifications.add({
                      message: `errors.self-exclusion.'general.unknown`,
                      level: 'error',
                    })
                  )
                }

                return endRegistration()
              },
              version: 1,
            }),
          ]
        },
        failure: res => {
          setShowVisualFeedback(true)
          if (res.value?.message === 'phone-not-unique') {
            setPhoneError(
              'errors.registration.phone-validation.phone-not-unique'
            )
          } else if (res.value?.message === 'phone-invalid') {
            setPhoneError('errors.registration.phone-validation.phone-invalid')
          } else {
            dispatch(
              Notifications.add({
                message: 'errors.registration.phone-verification.failed',
                level: 'error',
              })
            )
          }
          return Processes.stop(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST)
        },
        version: 2,
      }),
    ])
  }

  function secondaryAction() {
    setStep(RegistrationStep.Credentials)
    dispatch([
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
    history.push('/?register=me')
  }

  if (fingerprint.fetching || authenticated) {
    return null
  }

  return (
    <Forms.Provider
      name={formName}
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (!registrationInProgress) {
          startRegistration()
        }

        switch (step) {
          case RegistrationStep.Credentials: {
            if (
              Object.keys(formErrors).some(error =>
                [
                  '#/properties/email',
                  '#/properties/password',
                  '#/properties/username',
                ].includes(error)
              )
            ) {
              setShowVisualFeedback(true)
            } else {
              handleCredentialsSubmit(data)
            }
            break
          }

          case RegistrationStep.Identity: {
            if (
              formErrors.constructor === Object &&
              Object.keys(formErrors).length > 0
            ) {
              setShowVisualFeedback(true)
            } else {
              handleRegister(data)
            }
            break
          }

          default: {
            break
          }
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials ? () => secondaryAction() : null
        }
        activeQueryName="register"
        additionalQueries={{ params: null, token: null, provider: null }}
        onSideEffect={secondaryAction}
        title={i18n.translate(`register.title.step-${step + 1}`)}
      >
        <Common.Box pt="1">
          <ProgressBar step={step} steps={3} />
          <RegistrationForm
            countryNames={countryNames}
            countryCallingCodes={countryCallingCodes}
            emailErrorKey={emailError}
            phoneErrorKey={phoneError}
            usernameErrorKey={usernameError}
            showVisualFeedback={showVisualFeedback}
            step={step}
            onClearEmailErrorKey={() => setEmailError(null)}
            onClearPhoneErrorKey={() => setPhoneError(null)}
            onClearUsernameErrorKey={() => setUsernameError(null)}
          />
        </Common.Box>
        {step === Constants.RegistrationStep.Credentials && (
          <Common.Box pt="1" fontSize="14px" textAlign="center">
            <Common.Box as="span" pr="6px" opacity="0.6">
              {i18n.translate('landing-page.has-account')}
            </Common.Box>
            <ReactRouter.Link
              to={`?${loginQuery}`}
              style={{ textDecoration: 'underline', fontWeight: 700 }}
            >
              {i18n.translate('main-menu.login')}
            </ReactRouter.Link>
          </Common.Box>
        )}
      </QueryDrawer>
    </Forms.Provider>
  )
}
