import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Session from './session'
import { RegistrationDrawer } from './registration-drawer'

export function Registration() {
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )

  if (authenticated) {
    return null
  }

  return <RegistrationDrawer />
}
