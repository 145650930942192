import * as React from 'react'
import * as R from 'ramda'

import { MediaQueries } from '../constants'

import { QuickNavigationMenuContainer } from './quick-navigation-menu-container'

export function QuickNavigationMenu() {
  const [shouldRender, setShouldRender] = React.useState(false)

  React.useEffect(() => {
    const queries = MediaQueries
    const mediaQueryLists = {}
    const keys = R.keys(queries)

    function handleChange(isMatching) {
      const match = R.filter(query => query, isMatching)
      switch (R.head(R.keys(match))) {
        case 'sm':
          setShouldRender(true)
          break
        default:
          setShouldRender(false)
          break
      }
    }

    const handleQueryListener = () => {
      const isMatching = R.reduce(
        (acc, media) => {
          acc[media] = Boolean(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        {},
        R.keys(queries)
      )

      handleChange(isMatching)
    }

    R.forEach(media => {
      mediaQueryLists[media] = window.matchMedia(queries[media])
    }, keys)

    R.forEach(media => {
      mediaQueryLists[media].addListener(handleQueryListener)
    }, keys)

    return () => {
      R.forEach(media => {
        mediaQueryLists[media].removeListener(handleQueryListener)
      }, keys)
    }
  }, [])

  React.useEffect(() => {
    if (window.screen.width < 800) {
      setShouldRender(true)
    } else {
      setShouldRender(false)
    }
  }, [])

  if (shouldRender) {
    return <QuickNavigationMenuContainer />
  }
  return null
}

// For @loadable/components
export default QuickNavigationMenu
