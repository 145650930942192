import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as I18n from '@rushplay/i18n'

import * as Icons from './icons'

const HINTS = {
  passwordLength: /.{8,}/,
  passwordContainsNumber: /\d/,
  passwordContainsCapitalLetter: /[A-Z]+/,
  passwordContainsSpecialCharacter: /(?=.*[@$!%*#?&])/,
}

export function PasswordHints(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, { noRegister: true })

  return (
    <Common.Box mt="1">
      <Common.Box fontSize="12px" lineHeight="120%" mb="12px" opacity="0.6">
        {i18n.translate(`hint.header`)}
      </Common.Box>
      {Object.entries(HINTS).map(hint => (
        <Common.Box key={hint[0]} display="flex" alignItems="center" py="4px">
          <Common.Box
            mr="4px"
            display="inline-flex"
            color={
              field.value && hint[1].test(field.value)
                ? 'success'
                : 'hsla(0, 0%, 100%, 0.4)'
            }
          >
            <Icons.CheckCircle />
          </Common.Box>
          <Common.Text fontSize="12px" lineHeight="120%" opacity="0.6">
            {i18n.translate(`hint.${Herz.Utils.Strings.toKebabCase(hint[0])}`)}
          </Common.Text>
        </Common.Box>
      ))}
    </Common.Box>
  )
}

PasswordHints.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default PasswordHints
