import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const QuickNavigationMenuSlot = styled.div`
  padding: 0;
  background: none;
  position: absolute;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  text-decoration: none;
  text-underline: none;

  ${props =>
    css({
      top: props.top,
      left: props.left,
      bottom: props.bottom,
      right: props.right,
    })};

  ${props =>
    props.variant === 'regular' &&
    `
     height: 34px;
     width: 46px;
     transform: translate(-50%, -50%);
  `};

  ${props =>
    props.variant === 'circular' &&
    `
    height: 60px;
    width: 60px;
    border-radius: 40px;
    background: #13202c;
  `};

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
  `};
`

const Icon = styled.img`
  height: 20px;
  width: 20px;
`

export function QuickNavigationMenuItem(props) {
  const handleAction = React.useCallback(() => {
    if (props.action) {
      props.action()
    } else {
      return false
    }
  }, [props.action])

  return (
    <QuickNavigationMenuSlot
      variant={props.variant}
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      left={props.left}
      to={props.to}
      disabled={props.disabled}
      id={props.id}
      as={props.action ? 'button' : ReactRouter.Link}
      {...(props.action
        ? {
            onClick: () => {
              handleAction()
            },
          }
        : {})}
    >
      {props?.iconSrc ? (
        <Icon as="img" alt={props.title} src={props.iconSrc} />
      ) : null}
      <Common.Text
        fontFamily="Raleway"
        fontSize="10px"
        fontWeight="700"
        lineHeight="12px"
        textAlign="center"
        color={props.disabled ? '#8f8888' : '#FFF'}
      >
        {props.title}
      </Common.Text>
    </QuickNavigationMenuSlot>
  )
}

QuickNavigationMenuItem.defaultProps = {
  variant: 'regular',
  disabled: false,
}

QuickNavigationMenuItem.propTypes = {
  iconSrc: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  variant: PropTypes.oneOf(['regular', 'circular']),
  disabled: PropTypes.bool,
  action: PropTypes.func,
  id: PropTypes.string,
}
