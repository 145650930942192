import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Session from './session'
import * as Promotions from './promotions'
import * as Suspense from './suspense'
import { QueryDrawer } from './query-drawer'
import { Toast } from './toast'
import { Transaction } from './transaction'
import { TransactionStep } from './constants'
import { usePrev } from './use-prev'

const LoginBox = loadable(() => import('./login-box'))

export function Wallet() {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()

  // TODO refactor with herz
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const search = location?.search?.substr(1) || ''
  const searchParams = new URLSearchParams(search)
  const query = searchParams.get('wallet')

  const prevQuery = usePrev(query)
  const [step, setStep] = React.useState(TransactionStep.Amount)
  const [walletQuery, setWalletQuery] = React.useState(query)

  React.useEffect(() => {
    if (authenticated && query && prevQuery !== query) {
      dispatch(Promotions.fetch())
    }
  }, [query, prevQuery])

  React.useEffect(() => {
    if (authenticated && prevQuery !== query && !query) {
      setStep(TransactionStep.Amount)
      dispatch(Promotions.optOutOfAll())
    }
  }, [query, prevQuery])

  React.useEffect(() => {
    if (query && query !== walletQuery) {
      setTimeout(() => {
        setWalletQuery(query)
        setStep(TransactionStep.Amount)
      }, 100)
    }
  }, [walletQuery, query])

  return (
    <QueryDrawer
      activeQueryName="wallet"
      title={i18n.translate(`wallet.${walletQuery}`)}
    >
      <Suspense.Boundary>
        {authenticated ? (
          <Transaction
            onStepChange={setStep}
            step={step}
            transactionType={query}
          />
        ) : (
          <>
            <Common.Box fontSize="14px" fontWeight="600" pt={0} pb={0}>
              <Toast variant="info">{i18n.translate('wallet.info')}</Toast>
            </Common.Box>
            <LoginBox />
          </>
        )}
      </Suspense.Boundary>
    </QueryDrawer>
  )
}

// for @loadable/component
export default Wallet
