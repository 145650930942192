import * as React from 'react'
import * as Urql from 'urql'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Session from '../session'
import { Close, Play } from '../icons'
import { QuickNavigationMenuOffset } from '../constants'
import { comm100 } from '../support-chat-api'
import { useGameInfoUrlQuery } from '../use-game-info-url-query'
import { useMenuQueries } from '../use-menu-queries'
import { useSafeUpdateQuery } from '../use-safe-update-query'

import { QuickNavigationMenuItem } from './quick-navigation-menu-item'

const Wrapper = styled.div`
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 105;
  background: #142737;
  height: 56px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 50%;
    width: 68px;
    height: 22px;
    transform: translate(-100%, -100%);
    background-image: url(/images/mobile-nav-curve-left.svg);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    left: 50%;
    width: 68px;
    height: 22px;
    transform: translate(0, -100%);
    background-image: url(/images/mobile-nav-curve-right.svg);
  }

  ${css({
    display: ['flex', 'none'],
  })}
`

const Switcher = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  background: #0094ff;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  z-index: 106;
  ${props =>
    css({
      transform:
        props.variant === 'circular'
          ? 'translate(-50%, -50%)'
          : 'translate(-50%, -65%)',
    })};
`

const CircularWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 130px;
  z-index: 105;
  transform: translateX(-50%);
`

const RegularMenuAnchor = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const getGame = `
  query Game($id: ID!) {
    game(id: $id) {
      displayProvider
      id
      provider
      title
    }
  }
`

const getGameProviders = `
  query GameProviders {
    providers {
      name
      type
    }
  }
`

// function encodeQuery(query) {
//   return encodeURIComponent(JSON.stringify(query))
// }

// function getProviderMenuLink(providersList, providerName, providerDisplayName) {
//   if (providersList) {
//     const foundProvider = providersList.find(provider =>
//       provider.name.includes(providerName)
//     )
//     const foundDisplayProvider = providersList.find(provider =>
//       provider.name.includes(providerDisplayName)
//     )
//
//     if (foundProvider) {
//       return `/casino/search?q=${encodeQuery({
//         [`${foundProvider.type}s`]: foundProvider.name,
//       })}`
//     }
//
//     if (foundDisplayProvider) {
//       return `/casino/search?q=${encodeQuery({
//         [`${foundDisplayProvider.type}s`]: foundDisplayProvider.name,
//       })}`
//     }
//   }
//
//   if (providerDisplayName) {
//     return `/casino/search?q=${encodeQuery({ ['text']: providerDisplayName })}`
//   }
//
//   if (providerName) {
//     return `/casino/search?q=${encodeQuery({ ['text']: providerName })}`
//   }
//
//   return '/casino/search'
// }

function onLiveChatClick() {
  comm100.openChatWindow()
}

export function QuickNavigationMenuContainer() {
  const [contextualNavigation, setContextualNavigation] = React.useState([])
  const [circularMenuOpen, setCircularMenuOpen] = React.useState(false)
  const quickNavEnabled = contextualNavigation.length !== 0
  const authenticated = Session.useAuthenticated()
  const location = ReactRouter.useLocation()
  const i18n = I18n.useI18n()
  const { depositQuery, loginQuery } = useMenuQueries()
  const liveBetsQuery = useSafeUpdateQuery({ 'bt-path': '/live' })
  const homeSportQuery = useSafeUpdateQuery({ 'bt-path': '/' })
  const gameInfoQuery = useGameInfoUrlQuery()
  const match = ReactRouter.useRouteMatch('/casino/games/:gameId')
  const gameIdParam = match?.params?.gameId ?? null

  const [gameResponse] = Urql.useQuery({
    query: getGame,
    variables: { id: gameIdParam },
    pause: gameIdParam === null,
  })
  const [providersResponse] = Urql.useQuery({ query: getGameProviders })

  const providersList = providersResponse?.data?.providers ?? null
  const game = (gameIdParam && gameResponse?.data?.game) ?? null

  React.useEffect(() => {
    // We're decided to make one version to go live, for now.

    // let currentNav = []
    //
    // if (location.pathname.includes('/sports')) {
    //   currentNav = [
    //     {
    //       title: 'quick-menu.all-bets',
    //       iconSrc: '/icons/cash-coins.svg',
    //       to: '/sports?bt-path=%2Fbets',
    //       right: '90px',
    //     },
    //     {
    //       title: 'quick-menu.my-bets',
    //       iconSrc: '/icons/user-coins.svg',
    //       to: '/sports?bt-path=%2Fbets',
    //       right: '30px',
    //     },
    //     {
    //       title: 'quick-menu.betslip',
    //       iconSrc: '/icons/cash-slip.svg',
    //       action: () => {},
    //       id: 'customBetslipButton',
    //       left: '70px',
    //     },
    //     {
    //       title: 'quick-menu.live-chat',
    //       iconSrc: '/icons/live-chat.svg',
    //       action: onLiveChatClick,
    //       left: '130px',
    //     },
    //   ]
    // } else {
    //   currentNav = [
    //     {
    //       title: 'quick-menu.provider',
    //       iconSrc: '/icons/grid.svg',
    //       to: getProviderMenuLink(
    //         providersList,
    //         game?.provider,
    //         game?.displayProvider
    //       ),
    //       right: '90px',
    //     },
    //     {
    //       title: 'quick-menu.spinlira',
    //       iconSrc: '/icons/spinlira-cash.svg',
    //       to: '/casino',
    //       right: '30px',
    //       disabled: true,
    //     },
    //     {
    //       title: 'quick-menu.category',
    //       iconSrc: '/icons/shapes.svg',
    //       to:
    //         gameInfoQuery.referrer && gameInfoQuery.referrerPath
    //           ? gameInfoQuery.referrerPath
    //           : '/casino',
    //       left: '70px',
    //     },
    //     {
    //       title: 'quick-menu.live-chat',
    //       iconSrc: '/icons/live-chat.svg',
    //       action: onLiveChatClick,
    //       left: '130px',
    //     },
    //   ]
    // }

    const currentNav = [
      {
        title: 'quick-menu.deposit',
        iconSrc: '/icons/deposit.svg',
        to: authenticated ? `?${depositQuery}` : `?${loginQuery}`,
        right: '90px',
      },
      {
        title: 'quick-menu.spinlira',
        iconSrc: '/icons/spinlira-cash.svg',
        to: '/casino',
        right: '30px',
      },
      {
        title: 'quick-menu.bonus',
        iconSrc: '/icons/shield.svg',
        to: '?promotions=campaigns',
        left: '70px',
      },
      {
        title: 'quick-menu.live-chat',
        iconSrc: '/icons/live-chat.svg',
        action: onLiveChatClick,
        left: '130px',
      },
    ]

    setContextualNavigation(currentNav)
  }, [location.pathname, gameInfoQuery.referrer, providersList, game?.provider])

  React.useEffect(() => {
    const body = document.querySelector('body')
    if (body && quickNavEnabled && gameIdParam === null) {
      body.style.paddingBottom = QuickNavigationMenuOffset
    } else if (body && body.style.paddingBottom === QuickNavigationMenuOffset) {
      body.style.paddingBottom = ''
    }
  }, [quickNavEnabled, game, location])

  if (!quickNavEnabled) {
    return null
  }

  if (circularMenuOpen) {
    return (
      <CircularWrapper>
        <Switcher
          variant="circular"
          onClick={() => setCircularMenuOpen(prevState => !prevState)}
        >
          <Common.Text fontSize="16px">
            <Close />
          </Common.Text>
        </Switcher>
        <QuickNavigationMenuItem
          variant="circular"
          bottom="54px"
          left="-30px"
          title={i18n.translate('quick-menu.slots')}
          iconSrc="/icons/chip.svg"
          to="/casino/3/slots"
        />
        <QuickNavigationMenuItem
          variant="circular"
          bottom="35px"
          left="35px"
          title={i18n.translate('quick-menu.live-chat')}
          iconSrc="/icons/live-chat.svg"
          action={onLiveChatClick}
        />
        <QuickNavigationMenuItem
          variant="circular"
          top="-30px"
          left="54px"
          title={i18n.translate('quick-menu.sports')}
          iconSrc="/icons/bet.svg"
          to={`/sports?${homeSportQuery}`}
        />
        <QuickNavigationMenuItem
          variant="circular"
          top="34px"
          left="35px"
          title={i18n.translate('quick-menu.bonus')}
          iconSrc="/icons/shield.svg"
          to="?promotions=campaigns"
        />
        <QuickNavigationMenuItem
          variant="circular"
          top="54px"
          left="-30px"
          title={i18n.translate('quick-menu.live-bets')}
          iconSrc="/icons/slot-machine.svg"
          to={`/sports?${liveBetsQuery}`}
        />
        <QuickNavigationMenuItem
          variant="circular"
          top="-30px"
          right="54px"
          title={i18n.translate('quick-menu.deposit')}
          iconSrc="/icons/deposit.svg"
          to={authenticated ? `?${depositQuery}` : `?${loginQuery}`}
        />
        <QuickNavigationMenuItem
          variant="circular"
          top="34px"
          right="35px"
          title={i18n.translate('quick-menu.spinlira')}
          iconSrc="/icons/spinlira-cash.svg"
          to={authenticated ? `?${loginQuery}` : `?${loginQuery}`}
        />
        <QuickNavigationMenuItem
          variant="circular"
          bottom="35px"
          right="35px"
          title={i18n.translate('quick-menu.bet')}
          iconSrc="/icons/coins.svg"
          to={`/sports?${homeSportQuery}`}
        />
      </CircularWrapper>
    )
  }

  return (
    <Wrapper>
      <Switcher
        variant="regular"
        onClick={() => setCircularMenuOpen(prevState => !prevState)}
      >
        <Common.Text fontSize="16px">
          <Play />
        </Common.Text>
        <Common.Text
          fontFamily="Raleway"
          fontSize="10px"
          fontWeight="700"
          lineHeight="12px"
          textAlign="center"
          color="#142737"
        >
          {i18n.translate('quick-menu.play')}
        </Common.Text>
      </Switcher>
      <RegularMenuAnchor>
        {contextualNavigation.length > 0 &&
          contextualNavigation.map(item => (
            <QuickNavigationMenuItem
              {...item}
              variant="regular"
              key={item.title}
              title={i18n.translate(item.title)}
            />
          ))}
      </RegularMenuAnchor>
    </Wrapper>
  )
}
