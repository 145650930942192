import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import LoginForm from './login-form'

export function LoginBox() {
  const i18n = I18n.useI18n()

  return (
    <React.Fragment>
      <LoginForm />
      <Common.Box pt={2} textAlign="center" fontSize={2}>
        {i18n.translate('login-page.create-account.content')}
        <Common.Space pt={0}>
          <ReactRouter.Link to="?register=me" data-testid="login-page-register">
            <Common.Text textDecoration="underline">
              {i18n.translate('login-page.create-account.cta')}
            </Common.Text>
          </ReactRouter.Link>
        </Common.Space>
      </Common.Box>
    </React.Fragment>
  )
}

// For @loadable/components
export default LoginBox
