import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Processes from '@rushplay/processes'
import * as I18n from '@rushplay/i18n'
import loadable from '@loadable/component'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { Button } from '../button'
import { Checkbox } from '../checkbox'
import { HtmlContent } from '../html-content'
import { InputField } from '../input-field'
import { InputPasswordField } from '../input-password-field'
import { SelectField } from '../select-field'

const FieldResponse = loadable(() => import('../field-response'))

function AllowCheckbox(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <Checkbox
      id={field.name}
      name={field.name}
      invalid={field.errors?.length > 0}
      fontSize={2}
      checked={field.value}
      label={
        <HtmlContent
          html={{
            __html: i18n.translate(field.label),
          }}
        />
      }
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

AllowCheckbox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
}

function TermsCheckBox(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <>
      <Checkbox
        id={field.name}
        invalid={field.errors?.length > 0}
        name={field.name}
        checked={field.value}
        label={
          <HtmlContent
            html={{
              __html: i18n.translate(field.label),
            }}
          />
        }
        value={field.value}
        fontSize={2}
        onChange={() => field.onChangeValue(!field.value)}
      />
      <FieldResponse scope={props.scope} />
    </>
  )
}

TermsCheckBox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
}

export function RegistrationForm(props) {
  const i18n = I18n.useI18n()

  const [cookieData] = Cookies.useCookie(
    Constants.CookieKeys.REGISTRATION_FORM_DATA
  )

  const step1Loading = ReactRedux.useSelector(state => {
    return Processes.isRunning(state.processes, {
      ids: [
        Constants.ProcessesIds.EMAIL_UNIQUENESS_REQUEST,
        Constants.ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST,
        Constants.ProcessesIds.USERNAME_UNIQUENESS_REQUEST,
      ],
    })
  })

  const step2Loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [Constants.ProcessesIds.REGISTER_REQUEST],
    })
  )

  const callingCodeOptions = React.useMemo(
    () =>
      props.countryCallingCodes?.map(item => ({
        value: item,
        label: `+${item}`,
      })),
    [props.countryCallingCodes]
  )
  return (
    <React.Fragment>
      {props.step === Constants.RegistrationStep.Credentials && (
        <Common.Box display="grid" gridGap={1}>
          <InputField
            customErrorKey={props.usernameErrorKey}
            scope="#/properties/username"
            onClearCustomErrorKey={props.onClearUsernameErrorKey}
          />
          <InputField
            autoComplete="email"
            customErrorKey={props.emailErrorKey}
            inputMode="email"
            initialValue={cookieData?.email ?? ''}
            scope="#/properties/email"
            onClearCustomErrorKey={props.onClearEmailErrorKey}
          />
          <InputPasswordField
            autoComplete="new-password"
            initialValue={cookieData?.password ?? ''}
            scope="#/properties/password"
          />

          <Common.Box pt={0} width="100%">
            <Button
              stretch
              fontSize="14px"
              type="submit"
              variant="primary"
              disabled={step1Loading}
            >
              <Common.Box py="6.5px">
                {i18n.translate('register.step1.cta')}
              </Common.Box>
            </Button>
          </Common.Box>
        </Common.Box>
      )}

      {props.step === Constants.RegistrationStep.Identity && (
        <Common.Box display="grid" gridGap={1}>
          <Common.Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap="8px"
            alignItems="start"
          >
            <InputField
              autoComplete="given-name"
              scope="#/properties/firstName"
            />
            <InputField
              autocomplete="family-name"
              scope="#/properties/lastName"
            />
          </Common.Box>
          <Common.Box>
            <Common.Box fontSize="12px" fontFamily="body" pb="0">
              {i18n.translate('registration.birthdate')}
            </Common.Box>
            <Common.Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridGap="8px"
              alignItems="start"
            >
              <SelectField
                contentTranslated
                autoComplete="bday-month"
                inputMode="numeric"
                maxLength="2"
                initialValue="1"
                scope="#/properties/bdayMonth"
                options={Constants.MONTHS}
              />
              <SelectField
                contentTranslated
                autoComplete="bday-day"
                inputMode="numeric"
                maxLength="2"
                scope="#/properties/bdayDay"
                initialValue="1"
                options={Constants.DAYS}
              />
              <InputField
                autoComplete="bday-year"
                inputMode="numeric"
                maxLength="4"
                scope="#/properties/bdayYear"
              />
            </Common.Box>
          </Common.Box>

          <Common.Box
            display="grid"
            gridTemplateColumns="80px 1fr"
            gridGap={0}
            alignItems="start"
          >
            <SelectField
              contentTranslated
              disabled={callingCodeOptions?.length === 1}
              autoComplete="tel-country-code"
              initialValue={callingCodeOptions[0]?.value}
              options={callingCodeOptions}
              scope="#/properties/countryCallingCode"
            />
            <InputField
              autoComplete="tel"
              customErrorKey={props.phoneErrorKey}
              inputMode="tel"
              maxLength="11"
              scope="#/properties/phonenumber"
              onClearCustomErrorKey={props.onClearPhoneErrorKey}
            />
          </Common.Box>

          <TermsCheckBox initialValue scope="#/properties/terms" />
          <AllowCheckbox initialValue scope="#/properties/promotional" />
          <Common.Box pb={2} width="100%">
            <Button
              stretch
              fontSize="14px"
              type="submit"
              variant="primary"
              disabled={step2Loading}
            >
              <Common.Box py="6.5px" width="100%">
                {i18n.translate('register.step2.cta')}
              </Common.Box>
            </Button>
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  countryCallingCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  emailErrorKey: PropTypes.string,
  phoneErrorKey: PropTypes.string,
  usernameErrorKey: PropTypes.string,
  step: PropTypes.number.isRequired,
  onClearEmailErrorKey: PropTypes.func,
  onClearPhoneErrorKey: PropTypes.func,
  onClearUsernameErrorKey: PropTypes.func,
}
